import axios from "axios";
import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Button} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Card from '../../../components/Card'
import { error } from "../../../notify";

// img
// import facebook from '../../../assets/images/brands/fb.svg'
// import google from '../../../assets/images/brands/gm.svg'
// import instagram from '../../../assets/images/brands/im.svg'
// import linkedin from '../../../assets/images/brands/li.svg'
// import auth1 from '../../../assets/images/auth/01.png'

const SignIn = () => {
   let history = useNavigate()
   const ERR_NETWORK_MESSAGE = 'Server is not available.'
   const [email, setEmail] = useState('')
   const [password, setPassword] = useState('')

   let token = sessionStorage.getItem("revizeTrainingToken");
   
   useEffect(() => {
      // Function to call on page load
      if(token) {
         history('/dashboard');
      }
    }, [token, history]);

   const hanldeEmailChange = (e) => {
      e.persist();
      setEmail(e.target.value);
   }

   const hanldePasswordChange = (e) => {
      e.persist();
      setPassword(e.target.value);
   }

   const hanldeOnSubmit = async(e) => {
      e.preventDefault();
      e.target.reset();

      const headers = {"Content-Type": "application/x-www-form-urlencoded"};
      var data = `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`;

      axios.post(`${process.env.REACT_APP_SERVER_URL}/api/token`, 
         data, {headers: headers})
         .then((response) => {
            console.log(response);
            const data = response?.data;
            const token = data.access_token;
            if (!token) {
               alert('Unable to login. Please try after some time.');
               return;
            }
            sessionStorage.clear();
            sessionStorage.setItem("revizeTrainingToken", token);
            sessionStorage.setItem("userRole", data.role);
			sessionStorage.setItem("firstName", data.firstName);
            setTimeout(() => {
               history('/dashboard');
            }, 500);
      }).catch((err) => {
         if(err === 'ERR_NETWORK') {
            error(ERR_NETWORK_MESSAGE, true)
         } else {
            error(err.response.data.detail, true)	
         }
      });
   }

   const areAllFieldsFilled = email !== "" && password !== "";

   return (
      <>
         {!token && (
         <section className="login-content" style={{backgroundColor:"#e6e6e6"}}>
            <Link to="/dashboard" className="navbar-brand d-flex" style={{padding: "10px", position: "absolute"}}>
               <svg width="88.906" height="22.281" viewBox="0 0 88.906 22.281">
                  <path id="revizelogo" className="cls-1" d="M0.93,22H5.49v-5.94a6.067,6.067,0,0,1,1.3-4.29,4.7,4.7,0,0,1,3.585-1.38h0.24V5.624a4.6,4.6,0,0,0-3.24.9,6.688,6.688,0,0,0-1.89,2.64V5.924H0.93V22Zm14.73-9.3a4.666,4.666,0,0,1,1.155-2.49,3.062,3.062,0,0,1,2.325-.93,2.99,2.99,0,0,1,2.31.945A4.443,4.443,0,0,1,22.53,12.7H15.66Zm7.74,8.865a8.092,8.092,0,0,0,2.76-2.2l-2.61-2.31a6.939,6.939,0,0,1-1.86,1.26,5.083,5.083,0,0,1-2.04.39,4.085,4.085,0,0,1-2.565-.8,3.84,3.84,0,0,1-1.365-2.355H26.91q0.03-.33.045-0.63t0.015-.57a11.548,11.548,0,0,0-.465-3.285,8.058,8.058,0,0,0-1.44-2.79A7.081,7.081,0,0,0,22.62,6.344a7.834,7.834,0,0,0-3.48-.72,7.626,7.626,0,0,0-5.73,2.46,8.237,8.237,0,0,0-1.635,2.67,8.994,8.994,0,0,0-.585,3.24v0.06a8.708,8.708,0,0,0,.63,3.375,7.906,7.906,0,0,0,1.755,2.625,7.738,7.738,0,0,0,2.67,1.71,9.164,9.164,0,0,0,3.345.6A8.561,8.561,0,0,0,23.4,21.569Zm10.35,0.555h4.14l6.33-16.2H39.48l-3.63,10.77-3.6-10.77H27.42ZM45.57,22h4.56V5.924H45.57V22ZM45.45,4.154h4.8V0.1h-4.8v4.05ZM52.38,22H66.36v-3.69H58.02l8.34-9.18V5.924H52.65v3.69h8.04l-8.31,9.18V22ZM72,12.7a4.666,4.666,0,0,1,1.155-2.49,3.062,3.062,0,0,1,2.325-.93,2.99,2.99,0,0,1,2.31.945,4.443,4.443,0,0,1,1.08,2.475H72Zm7.74,8.865a8.092,8.092,0,0,0,2.76-2.2l-2.61-2.31a6.939,6.939,0,0,1-1.86,1.26,5.083,5.083,0,0,1-2.04.39,4.085,4.085,0,0,1-2.565-.8,3.84,3.84,0,0,1-1.365-2.355h11.19q0.03-.33.045-0.63t0.015-.57a11.548,11.548,0,0,0-.465-3.285,8.058,8.058,0,0,0-1.44-2.79,7.081,7.081,0,0,0-2.445-1.935,7.834,7.834,0,0,0-3.48-.72,7.626,7.626,0,0,0-5.73,2.46,8.237,8.237,0,0,0-1.635,2.67,8.994,8.994,0,0,0-.585,3.24v0.06a8.708,8.708,0,0,0,.63,3.375,7.906,7.906,0,0,0,1.755,2.625,7.738,7.738,0,0,0,2.67,1.71,9.164,9.164,0,0,0,3.345.6A8.561,8.561,0,0,0,79.739,21.569ZM85.019,22h4.83V17.2h-4.83V22Z" transform="translate(-0.938 -0.094)"></path>
               </svg>
            </Link>
            <Row className="m-0 align-items-center bg-grey vh-100" >
               <Col md="12">
                  <Row className="justify-content-center">
                     <Col md="4">
                        <Card className="card-transparent shadow-none d-flex justify-content-center mb-0">
                           <Card.Body>
                              <div style={{padding:"140px 40px 40px 40px"}}>
                                 <h3 className="mb-2 text-left">Hello! let's get started</h3>
                                 <p className="text-left" style={{fontSize: 18}}>Sign in to continue.</p>
                                 <Form action="" onSubmit={hanldeOnSubmit}>
                                    <Row style={{marginTop: "40px", marginBottom: "20px"}}>
                                       <Col lg="12">
                                          <Form.Group className="form-group" style={{marginBottom: "30px"}}>
                                             <Form.Control 
                                                onChange={(e) => hanldeEmailChange(e)} 
                                                type="email" 
                                                className="" 
                                                id="email" 
                                                aria-describedby="email" 
                                                placeholder="Email" 
                                                maxLength={100}
                                                style={{height: "50px"}} />
                                          </Form.Group >
                                       </Col>
                                       <Col lg="12" className="">
                                          <Form.Group className="form-group">
                                             <Form.Control 
                                                onChange={(e) => hanldePasswordChange(e)} 
                                                type="password" 
                                                className="" 
                                                id="password" 
                                                aria-describedby="password" 
                                                placeholder="Password" 
                                                maxLength={200}
                                                style={{height: "50px"}}/>
                                          </Form.Group>
                                       </Col>
                                       <Col lg="12" className="d-flex justify-content-between">
                                          {/* <Form.Check className="form-check mb-3">
                                             <Form.Check.Input type="checkbox" id="customCheck1" />
                                             <Form.Check.Label htmlFor="customCheck1">Remember Me</Form.Check.Label>
                                          </Form.Check> */}
                                          <Link to="/auth/recoverpw">Forgot Password?</Link>
                                       </Col>
                                    </Row>
                                    <Col lg="12" className="">
                                       {/* <div className="d-flex justify-content-center"> */}
                                          {/* <Button onClick={() => history('/dashboard')} type="button" variant="btn btn-primary">Sign In</Button> */}
                                          <Button disabled={!areAllFieldsFilled} type="submit" variant="btn btn-primary" style={{height: "60px", width: "100%"}}>SIGN IN</Button>
                                       {/* </div> */}
                                    </Col>
                                    {/* <p className="text-center my-3">or sign in with other accounts?</p>
                                    <div className="d-flex justify-content-center">
                                       <ListGroup as="ul" className="list-group-horizontal list-group-flush">
                                          <ListGroup.Item as="li" className="border-0 pb-0">
                                             <Link to="#"><Image src={facebook} alt="fb" /></Link>
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li" className="border-0 pb-0">
                                             <Link to="#"><Image src={google} alt="gm" /></Link>
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li" className="border-0 pb-0">
                                             <Link to="#"><Image src={instagram} alt="im" /></Link>
                                          </ListGroup.Item>
                                          <ListGroup.Item as="li" className="border-0 pb-0">
                                             <Link to="#"><Image src={linkedin} alt="li" /></Link>
                                          </ListGroup.Item>
                                       </ListGroup>
                                    </div>
                                    <p className="mt-3 text-center">
                                       Don’t have an account? <Link to="/auth/sign-up" className="text-underline">Click here to sign up.</Link>
                                    </p> */}
                                 </Form>
                              </div>
                           </Card.Body>
                        </Card>
                     </Col>
                  </Row>
                  {/* <div className="sign-bg">
                     <svg width="280" height="230" viewBox="0 0 431 398" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.05">
                           <rect x="-157.085" y="193.773" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 -157.085 193.773)" fill="#3B8AFF" />
                           <rect x="7.46875" y="358.327" width="543" height="77.5714" rx="38.7857" transform="rotate(-45 7.46875 358.327)" fill="#3B8AFF" />
                           <rect x="61.9355" y="138.545" width="310.286" height="77.5714" rx="38.7857" transform="rotate(45 61.9355 138.545)" fill="#3B8AFF" />
                           <rect x="62.3154" y="-190.173" width="543" height="77.5714" rx="38.7857" transform="rotate(45 62.3154 -190.173)" fill="#3B8AFF" />
                        </g>
                     </svg>
                  </div> */}
               </Col>
               {/* <Col md="6" className="d-md-block d-none bg-primary p-0 mt-n1 vh-100 overflow-hidden">
                  <Image src={auth1} className="Image-fluid gradient-main animated-scaleX" alt="images" />
               </Col> */}
            </Row>
         </section>
         )}
      </>
   )
}

export default SignIn
