import React from "react";
import { createRoot } from 'react-dom/client';

import NotificationsManager from "./NotificationsManager";
import Notification, { Color } from "./Notification";
import createContainer from "./createContainer";

const containerElement = createContainer();
let notify;
const root = createRoot(containerElement);

root.render(
  <React.StrictMode>
  <NotificationsManager
    setNotify={(notifyFn) => {
      notify = notifyFn;
    }}
  />
  </React.StrictMode>
  // containerElement
);

export { Notification, Color };

export function info(children, autoClose) {
  return notify({
    color: Color.info,
    children:<div>{children}</div>,
    autoClose,
  });
}

export function success(children, autoClose) {
  return notify({
    color: Color.success,
    children:<div>{children}</div>,
    autoClose,
  });
}

export function warning(children, autoClose) {
  return notify({
    color: Color.warning,
    children:<div>{children}</div>,
    autoClose,
  });
}

export function error(children, autoClose) {
  return notify({
    color: Color.error,
    children:<div>{children}</div>,
    autoClose,
  });
}