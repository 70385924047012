import React, { useState, useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Modal, Row, Form } from 'react-bootstrap';
import DeleteConfirmation from './DeleteConfirmation';
import moment from "moment";

import { Link } from 'react-router-dom'
import Card from '../../../components/Card'
import axios from 'axios';
import '../../../assets/scss/component.scss'
import { error, success } from "../../../notify";

const SitePendingFST = (props) => {
    let history = useNavigate();
    const ERR_NETWORK_MESSAGE = 'Server is not available.'
    const token = sessionStorage.getItem("revizeTrainingToken");
    const pendingFstApi = `${process.env.REACT_APP_SERVER_URL}/api/pending_fst_records`;
    const [fstData, setFSTData] = useState([]);

    let loaded = false;
    const location = useLocation();

    useEffect(() => {
        if(!loaded && token) {
            // eslint-disable-next-line
            loaded = true;
            if(null === location.state) {
                history('/site')
                return;
            }
            fetchData();
        }
    
    }, [])

    async function fetchData() {        
        const headers = { "Content-Type": "application/json", "Authorization": "Bearer " +  token};
    
        axios.get(pendingFstApi, {headers: headers})
        .then((response) => {
            const data = response.data;
            console.log('data', data)
            setFSTData(data);
        }).catch((err) => {
            if(err === 'ERR_NETWORK') {
                error("Server is not avalilable.", true)
            } else {
                error(err.response.data.detail, true)	
            }
            history('/site');
            });
    }

    return (
        <>
        <Row>
            <Col sm="12">
                <Card>
                    <Card.Header className="d-flex justify-content-between flex-wrap">
                        <div className="header-title">
                            <h4 className="card-title mb-0">Pending FST Records</h4>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="table-responsive">
                            <table className="table-hover table-striped table">
                                <thead>
                                    <tr>
                                        <th>Website Name</th>
                                        <th>Status</th>
                                        <th>Requested Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {fstData.map((item, index) => (
                                        <tr key={index} >
                                            <td width={"50%"}>
                                                {item.website_name} 
                                            </td>
                                            <td width={"25%"} >
                                                {item.status}
                                            </td>
                                            <td width={"25%"}>
                                            {item.requested_time ? moment(item.requested_time).format("MM/DD/YY, HH:mm") : ""}
                                            </td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        </>
    )
}

export default SitePendingFST