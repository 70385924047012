import {useEffect, useState} from 'react'
import {Row, Col, Form, Button} from 'react-bootstrap'
import Card from '../../../components/Card'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { error, success } from "../../../notify";

const ResetPassword = () => {
   let history = useNavigate()
   const ERR_NETWORK_MESSAGE = 'Server is not available.'
   const token = sessionStorage.getItem("revizeTrainingToken");
   const resetPasswordApi = `${process.env.REACT_APP_SERVER_URL}/api/user/resetPassword/?user_id=`;
   const [userId, setUserId] = useState(0);

   const [password, setPassword] = useState("")
   const [confirmPassword, setConfirmPassword] = useState("")
   const [passwordError, setPasswordError] = useState("")
   const [confirmPasswordError, setConfirmPasswordError] = useState("")

   const handlePasswordInput = (event) => {
      event.persist();
      setPassword(event.target.value);
   };

   const handleConfirmPasswordInput = (event) => {
      event.persist();
      setConfirmPassword(event.target.value);
   };

   const validatePassword = (event) => {
      event.preventDefault();
      if(password === "") {
          setPasswordError("Password is mandatory");
      } else if(confirmPassword !== "" && password !== confirmPassword) {
          setPasswordError("");
          setConfirmPasswordError("Password and confirm password is not matching");
      } else {
          setPasswordError("");
          setConfirmPasswordError("");
      }
   }

   const validateConfirmPassword = (event) => {
      event.preventDefault();
      if(confirmPassword === "") {
          setConfirmPasswordError("Confirm Password is mandatory");
      } else if(password !== confirmPassword) {
          setConfirmPasswordError("Password and confirm password is not matching");
      } else {
          setConfirmPasswordError("");
      }
   }

   useEffect(() => {
      if(!token) {
         history('/auth/sign-in');
      }
      const urlParams = new URLSearchParams(window.location.search);
      let userId = urlParams.get("userId");
      if(userId > 0) {
         setUserId(userId);
      }
   }, [token, history])
  
   const formSubmit = (event) => {
      event.preventDefault();
      event.target.reset();

      const headers = { "Content-Type": "application/json", "Authorization": "Bearer " +  token};
      var data = JSON.stringify({"newPassword": password, "website_id": 0});

      axios.put(resetPasswordApi + userId, 
         data, {headers: headers})
      .then((response) => {
         const data = response.data;
         success(data, true);
      })
      .catch((err) => {
         if(err === 'ERR_NETWORK') {
             error(ERR_NETWORK_MESSAGE, true)
         } else {
             error(err.response.data.detail, true)
         }
       });
      setPassword("");
      setConfirmPassword("");
   };

   const areAllFieldsFilled = password !== "" && confirmPassword !== "" && passwordError === "" && confirmPasswordError === "";

   return (
      <>
         <div>
            <Row className="justify-content-center">
               <Col sm="12" lg="12">
                  <Card className="card-transparent shadow-none d-flex justify-content-left mb-0 pb-4">
                     <Card.Header className="d-flex justify-content-between">
                        <Col lg="6">
                           <h4 className="mb-2 text-left">Reset Password</h4>
                        </Col>
                     </Card.Header>
                     <Card.Body>
                        <Form action="" onSubmit={formSubmit}>
                           <Row>
                              <Col lg="6">
                                 <Form.Group className="form-group">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control style={{borderColor: passwordError.length > 0 ? "red" : null}}
                                       type="password" 
                                       defaultValue="" 
                                       id="password" 
                                       placeholder="Password" 
                                       onChange={handlePasswordInput}
                                       onBlur={validatePassword}
                                       maxLength={200}
                                       />
                                    <span style={{color:"red"}}>
                                       {passwordError}
                                    </span>
                                 </Form.Group>
                              </Col>
                              <Col lg="6"></Col>
                              <Col lg="6">
                                 <Form.Group className="form-group">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control style={{borderColor: confirmPasswordError.length > 0 ? "red" : null}}
                                       type="password" 
                                       defaultValue="" 
                                       id="confirm-password" 
                                       placeholder="Confirm Password"
                                       onChange={handleConfirmPasswordInput}
                                       onBlur={validateConfirmPassword}
                                       maxLength={200}
                                       />
                                    <span style={{color:"red"}}>
                                       {confirmPasswordError}
                                    </span>
                                 </Form.Group>
                              </Col>
                              <Col lg="6"></Col>
                              <div className="col-6">
                                 <Button disabled={!areAllFieldsFilled} variant="btn btn-primary" type="submit" style={{display: "block", marginLeft: "auto", marginRight: "0"}}>Update</Button>
                              </div>
                              <div className="col-6"></div>
                           </Row>
                        </Form>
                     </Card.Body>
                  </Card>
               </Col>
            </Row>
         </div>
      </>
   )
}

export default ResetPassword
