import axios from 'axios'

axios.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem("revizeTrainingToken");
    
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
);

axios.interceptors.response.use(
    response => {
        return response
    },

    error => {
        if(error.code === 'ERR_NETWORK') {
            return Promise.reject(error.code);
        }
      return Promise.reject(error)
    }
  )